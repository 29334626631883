<template>
  <v-btn
    rounded
    small
    @click="toggle"
    class="search-local-global elevation-0"
  >{{text}}</v-btn>
</template>
<script>
import GlobalSearch from '@/pages/search/controllers/global-search'

export default {
  computed: {
    text () {
      switch (this.value) {
        case GlobalSearch.LOCAL_MODE: return this.$t('t.Local')
        default: return this.$t('t.Global')
      }
    }
  },
  methods: {
    toggle () {
      this.$emit('input', this.value === GlobalSearch.LOCAL_MODE ? GlobalSearch.GLOBAL_MODE : GlobalSearch.LOCAL_MODE)
    }
  },
  props: {
    value: String,
    scopeUpdate: Boolean
  }
}
</script>

<style lang="stylus">
.search-local-global
  width 70px
</style>
